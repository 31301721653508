@import "assets/fonts/roboto-slab/roboto-slab.css";
@import "assets/fonts/unison-pro/unison-pro.css";
@import "assets/fonts/robo/robo.css";
@import "assets/libs/FontAwesome_v6.5.2/css/all.css";
@import "assets/libs/FontAwesome_v6.5.2/css/sharp-regular.css";
@import "assets/libs/FontAwesome_v6.5.2/css/sharp-solid.css";

/*
font-family: 'RobotoSlab', sans-serif;
font-family: 'UnisonPro', sans-serif;
*/

:root {
  --swiper-theme-color: #002c6b;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-inactive-color: #cbd5e1;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .content-auto {
    content-visibility: auto;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transition: all 0.3s ease;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #fabd00;
    transition: all 0.3s ease;
  }
  ::-webkit-scrollbar-thumb {
    background: #b32cbd;
    border-radius: 100vh;
    transition: all 0.3s ease;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b32cbd;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .conic-gradient {
    background: conic-gradient(from -90deg, #ec2d40, #b21020, #b21020, #b21020);
  }
}

*,
*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "RobotoSlab", sans-serif;
}

.prontopsy-span-color span {
  color: #b32cbd;
}

.lazy-load-image-background {
  display: block !important;
  width: 100%;
}
swiper-slide {
  height: auto !important;
  display: flex;
}

.calendar-container .react-calendar__navigation {
  margin-bottom: 20px;
}

.calendar-container .react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  text-transform: uppercase !important;
  font-weight: 900;
}

.calendar-container abbr:where([title]) {
  font-size: 12px !important;
  text-decoration: none;
  font-weight: 400 !important;
  width: 100%;
  margin: 0 !important;
  padding: 2px 6px;
}

.calendar-container
  .react-calendar__month-view__weekdays__weekday
  .react-calendar__month-view__weekdays__weekday--current {
  background-color: #e1e1e1 !important;
}

.calendar-container .react-calendar {
  border: none !important;
  width: 100% !important;
}

.calendar-container .react-calendar__month-view__weekdays__weekday {
  text-align: left;
}

.calendar-container .react-calendar__month-view__days {
  display: flex;
}
.calendar-container button.react-calendar__month-view__days__day {
  background-color: #f3f3f3;
  width: 100%;
  height: 50px;
  display: flex;
  padding-top: 8px;
  padding-left: 12px;
  margin-bottom: 0; /*flex: 122px !important; border-right: 8px solid #fff;*/
  flex: auto !important;
}
.calendar-container .react-calendar__month-view__days__day abbr {
  font-weight: 400 !important;
}
.calendar-container .react-calendar__navigation__prev2-button {
  display: none;
}
.calendar-container .react-calendar__navigation__next2-button {
  display: none;
}
.calendar-container .react-calendar__navigation__prev-button {
  position: relative;
}
.calendar-container .react-calendar__navigation__prev-button::after {
  position: absolute;
  content: "";
  left: 9px;
  top: 11px;
  background-image: url(../public/left-arrow.png);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-color: #fff;
}
.calendar-container .react-calendar__navigation__prev-button:hover::after {
  background-color: #e6e6e6;
}
.calendar-container .react-calendar__navigation__prev-button:focus::after {
  background-color: #e6e6e6;
}
.calendar-container .react-calendar__navigation__next-button {
  position: relative;
}
.calendar-container .react-calendar__navigation__next-button::after {
  position: absolute;
  content: "";
  left: 9px;
  top: 11px;
  background-image: url(../public/right-arrow.png);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-color: #fff;
}
.calendar-container .react-calendar__navigation__next-button:hover::after {
  background-color: #e6e6e6;
}
.calendar-container .react-calendar__navigation__next-button:focus::after {
  background-color: #e6e6e6;
}
.calendar-container .react-calendar__month-view__weekdays {
  gap: 10px;
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-bottom: 8px;
}
.calendar-container .react-calendar__month-view__weekdays__weekday {
  background-color: #e1e1e1;
  text-align: center;
  flex: auto !important;
}
.calendar-container
  .react-calendar__month-view__weekdays__weekday.react-calendar__month-view__weekdays__weekday--current {
  background-color: #aaabaa;
}
.calendar-container .react-calendar__month-view__weekdays__weekday abbr {
  font-weight: bold !important;
  font-size: 13px !important;
}
.calendar-container .react-calendar__month-view__weekdays__weekday:last-child {
  margin-right: 0 !important;
}
.calendar-container .react-calendar__month-view__days {
  gap: 10px;
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.calendar-container .react-calendar__month-view__days__day:last-child {
  border-right: 0 !important;
}
.calendar-container
  .react-calendar__month-view__days__day:last-child.react-calendar__month-view__days__day--weekend {
  border-right: 0 !important;
}
.calendar-container
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active {
  background: #002c6b;
}
.react-calendar__tile--active {
  background: #1087ff !important;
}
.calendar-container
  button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active:hover {
  background: #3b7ddc;
}
.calendar-container
  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:hover {
  background-color: #3b7ddc;
  color: #fff;
}
.calendar-container
  .react-calendar__tile.react-calendar__month-view__days__day:hover {
  background-color: #3b7ddc;
  color: #fff;
}
.calendar-container .react-calendar__navigation__label span {
  font-size: 23px;
}
.calendar-container .react-calendar__navigation button:enabled:hover {
  background: transparent;
}

/* ///////////////////////////////////////////////Privacy Policy/////////////////////////////////////////////////////////// */
.privacy-policy h1 {
  color: #002c6b;
  font-size: 25px;
  font-family: "UnisonPro", sans-serif;
  font-weight: 600;
  padding-top: 20px;
  width: 80%;
}
.privacy-policy h2 {
  color: #002c6b;
  font-size: 25px;
  font-family: "UnisonPro", sans-serif;
  font-weight: 600;
  padding-top: 20px;
  width: 80%;
}
.privacy-policy h3 {
  color: #002c6b;
  font-size: 25px;
  font-family: "UnisonPro", sans-serif;
  font-weight: 600;
  padding-top: 20px;
  width: 80%;
  font-style: italic;
}
.privacy-policy h4 {
  color: #002c6b;
  font-size: 25px;
  font-family: "UnisonPro", sans-serif;
  font-weight: 600;
  padding-top: 20px;
  width: 80%;
}
.privacy-policy h5 {
  color: #002c6b;
  font-size: 25px;
  font-family: "UnisonPro", sans-serif;
  font-weight: 600;
  padding-top: 20px;
  width: 80%;
}
.privacy-policy p {
  font-size: 17px;
  padding: 10px 0;
}
.privacy-policy ul li {
  font-size: 17px;
  padding: 10px 0;
}
.privacy-policy ul {
  margin: 0.75em 0;
  padding: 0 1em;
  list-style: none;
}
.privacy-policy ul li::before {
  content: "";
  border-color: transparent #b32dbd;
  border-style: solid;
  border-width: 0.45em 0 0.45em 0.5em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 1.2em;
  position: relative;
}
.privacy-policy ul li {
  list-style: none;
}

@media only screen and (max-width: 600px) {
  .calendar-container abbr:where([title]) {
    padding: 0;
  }
  .calendar-container button.react-calendar__month-view__days__day {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .privacy-policy p {
    font-size: 16px;
  }
  .privacy-policy ul li {
    font-size: 16px;
  }
  .privacy-policy h1 {
    width: 100%;
    font-size: 20px;
  }
  .privacy-policy h2 {
    width: 100%;
    font-size: 20px;
  }
  .privacy-policy h3 {
    width: 100%;
    font-size: 20px;
  }
  .privacy-policy h4 {
    width: 100%;
    font-size: 20px;
  }
  .privacy-policy h5 {
    width: 100%;
    font-size: 20px;
  }
}

/* F  O  R    S  U  C  C  E  S  S    M  O  D  A  L */
.animate-check {
  animation: check 0.5s ease-in-out;
}

@keyframes check {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* FOR VIDEO CHAT */
.video-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
}

.publisher,
.subscribers {
  width: 100%;
  height: 50%;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.publisher {
  margin-bottom: 20px;
}

.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #333;
}

/* .react-calendar__tile {
  background: none;
} */

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s infinite;
}

@media screen and (max-width: 480px) {
  .calendar-container .react-calendar__month-view__weekdays {
    gap: 1px;
  }
  .calendar-container .react-calendar__month-view__days {
    gap: 4px;
  }
}

.OT_fit-mode-cover .OT_video-element {
  object-fit: contain !important;
}

@keyframes scroll-left-right {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.marquee {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: scroll-left-right 20s linear infinite;
}
